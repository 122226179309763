<template>
  <div
    id="tooltip"
    class="p-2 text-left min-w-max"
  >
    <img
      v-if="pet.icon"
      class="absolute -top-0.5 inline w-12 h-auto border-2 border-tooltip-border -left-16"
      :src="`https://cdn.wowclassicdb.com/icons/${pet.icon.name}.jpg`"
      :alt="pet.name"
    >
    <div class="flex flex-col items-center space-y-4">
      <div>
        <div>
          <div class="flex space-y-2 flex-col">
            <h1 class="font-bold text-lg text-tooltip-misc">
              {{ pet.name }}
            </h1>
            <div v-if="pet.petType">
              <span class="text-tooltip-misc">Type:</span>
              <span class="flex max-w-xs flex-wrap">
                {{ pet.petType.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'PetTooltip',
  props: {
    pet: {
      type: Object,
      required: true
    }
  }
})
</script>
<style lang="postcss" scoped>

  #tooltip {
    top: 5000px;
    left: 5000px;
  }
</style>

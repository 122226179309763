<template>
  <div
    v-if="titles.length > 0"
    class="font-semibold"
  >
    <div
      class="items-center block text-2xl bg-no-repeat h-7 "
    >
      Rewards
    </div>
    <div class="flex flex-row ml-6">
      <ul
        v-for="(title, i) in titles"
        :key="i"
        class="mt-2 mr-8 flex flex-col"
      >
        <li>
          <router-link
            class="text-item-poor"
            :to="`/title/${title.id}`"
            v-tooltip="{data: title, type: 'title'}"
          >
           Title Reward: <span v-html="formatName(title)" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import tooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
export default {
  name: 'AchievementReward',
  props: {
    titles: {
      type: Array,
      required: true
    }
  },
  mixins: [
    tooltipMixin
  ],
  methods: {
    formatName (title) {
      return title.name.replace('%s', '&lt;Name&gt;')
    }
  }
}
</script>

<style scoped>
.mr-8 {
  margin-right: 2rem;
}
.ml-6 {
  margin-left: 1.5rem;
}

ul {
  list-style: square !important;
}
</style>

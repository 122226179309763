
import Vue, { PropType } from 'vue';
import { Title } from '../../../../lib/src/models/Title';
export default Vue.extend({
  name: 'TitleTooltip',
  props: {
    title: {
      type: Object as PropType<Title>,
      required: true
    }
  },
  computed: {
    titleName () {
      return this.title ? this.title.name.replace('%s', '<Name>') : ''
    }
  }

})

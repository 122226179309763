<template>
  <article
    v-if="zone"
    class="mt-4 space-y-6 text-left"
  >
    <header class="text-left">
      <h1 class="font-sans text-3xl font- text-primary">
        {{ zone.name }}
      </h1>
      <h2
        v-if="zone.area"
        class="mb-3 font-sans text-sm font-bold text-gray-400 uppercase"
      >
        {{ zone.area.name }}
      </h2>
      <h2
        v-if="zone.suggestedLevel"
        class="mb-3 font-sans text-sm font-bold text-gray-400 uppercase"
      >
        Level {{ zone.suggestedLevel }} {{ zone.suggestedLevel !== 60 ? '+' : '' }}
      </h2>

      <h3
        v-if="zone.area && zone.area.description"
        class="prose-lg text-gray-200"
      >
        {{ zone.area.description }}
      </h3>
    </header>

    <section class="max-w-3xl">
      <h3 class="font-semibold prose-xl">
        Map
      </h3>
      <WorldMap
        :location-id="zone.id"
        @openMapModal="openMapModal"
      />
    </section>

    <section
      class="pt-6"
    >
      <RelatedTableFilterButtonBase :filter-options="zone.relatedTableFilterOptions" />
    </section>
    <div>
      <transition name="fade">
        <MapModal
          v-if="showMapModal"
          :location-id="zone.id"
          @close="closeMapModal"
        />
      </transition>
    </div>
  </article>
</template>

<script>
import WorldMap from '@/components/Map.vue'
import MapModal from '@/components/MapModal.vue'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'

export default {
  name: 'SingleZone',
  components: {
    WorldMap,
    MapModal,
    RelatedTableFilterButtonBase
  },

  props: {
    zone: {
      type: Object,
      default: undefined
    }
  },

  data () {
    return {
      showMapModal: false
    }
  },

  methods: {
    openMapModal () {
      this.showMapModal = true
    },
    closeMapModal () {
      this.showMapModal = false
    }
  }
}

</script>

<style lang="scss" scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>



import Vue, { PropType } from 'vue'
import { Spell } from '../../../../lib/src/models/Item'
export default Vue.extend({
  name: 'SpellTooltip',
  props: {
    spell: {
      type: Object as PropType<Spell>,
      required: true
    }
  },

  computed: {
    parseSpellDescriptionNewlines () {
      const data: string = this.spell.description
      if (data) {
        const value = data.replace(/(?:\\[rn]|[\r\n]+)+/g, '\n')
        return value
      } else return ''
    },
    spellResources () {
      if (this.spell.resourceType === 'Rune') {
        const runes = []
        const frost = (this.spell.resourceCost & 0x300) >> 8
        const unholy = (this.spell.resourceCost & 0x030) >> 4
        const blood = this.spell.resourceCost & 0x003
        if (frost) runes.push(`${frost} Frost`)
        if (unholy) runes.push(`${unholy} Unholy`)
        if (blood) runes.push(`${blood} Blood`)
        return runes.join(', ')
      } else {
        return `${this.spell.resourceCost}${
            this.spell.resourceCostIsPercentage
              ? `% of Base ${this.spell.resourceType}`
              : ' ' + this.spell.resourceType
        }`
      }
    }
  },

  methods: {
    getCooldownSecondsMinutesAndHours (cooldown: number) {
      const seconds = cooldown / 1000
      const minutes = seconds >= 60 ? seconds / 60 : null
      const hours = minutes && minutes >= 60 ? minutes / 60 : null
      const label = hours ? 'hour' : minutes ? 'min' : 'sec'
      return `${hours || minutes || seconds} ${label} cooldown`
    }
  }

})

<template>
  <div
    id="item-tooltip"
    class="flex flex-col p-2 text-left"
  >
    <div v-if="item.items">
      <div class="p-0 mx-0 mb-3.5 font-sans text-xl font-bold text-white ">
        Set Pieces
      </div>
      <span v-if="item.skill">
        Requires {{ item.skill.name }} {{ item.requiredSkill }}
      </span>
      <ul v-if="item.items">
        <div
          v-for="itemSetItem in item.items"
          :key="itemSetItem.id"
          v-tooltip="{data: itemSetItem, type: 'item'}"
        >
          <router-link
            class="flex m-1 text-left"
            style="grid-column: 1 / span 2;"
            :to="`/item/${
              itemSetItem.id
            }`"
          >
            <img
              class="object-cover w-6 h-6 max-w-full mr-2 border-0"
              :src="`https://cdn.wowclassicdb.com/icons/${
                itemSetItem.icon?.name
              }.jpg`"
              :alt="
                item.name
              "
            ><span
              :class="`mb-1
            q-${itemSetItem.itemQuality.name.toLowerCase()}`"
            >{{ itemSetItem.name }}</span>
          </router-link>
        </div>
      </ul>
      <div class="p-0 mx-0 my-3 font-sans text-xl font-bold text-white ">
        Set Bonuses
      </div>
      <ul
        v-if="item.spells"
        class="max-w-xs"
      >
        <div
          v-for="(itemSetBonus, i) in itemSetBonuses"
          :key="i"
          v-tooltip="{data: itemSetBonus, type: 'spell'}"
        >
          <router-link
            v-if="itemSetBonus.description"
            class="flex m-1 text-left"
            style="grid-column: 1 / span 2;"
            :to="`/spell/${itemSetBonus.id}`"
          >
            <div><span class="mr-2 text-gray-300">{{ itemSetBonus.ItemSetBoni.amountOfPieces }} Pieces:</span><span class="transition-colors cursor-pointer text-primary hover:text-white">{{ itemSetBonus.description }}</span></div>
          </router-link>
          <router-link
            v-if="!itemSetBonus.description && itemSetBonus.name.length"
            class="flex m-1 text-left"
            style="grid-column: 1 / span 2;"
            :to="`/spell/${itemSetBonus.id}`"
          >
            <div><span class="mr-2 text-gray-300">{{ itemSetBonus.ItemSetBoni.amountOfPieces }} Pieces:</span><span class="transition-colors cursor-pointer text-primary hover:text-white">{{ parseSpellEffectDescription(itemSetBonus) }}</span></div>
          </router-link>
        </div>
      </ul>
    </div>
    <img
      v-if="item.icon && showIcon"
      class="absolute -top-0.5 inline w-12 h-auto border-2 border-tooltip-border -left-16 box-border"
      :src="`https://cdn.wowclassicdb.com/icons/${item.icon?.name}.jpg`"
      :alt="item.name"
    >
    <div>
      <p
        v-if="item.itemQuality"
        :style="{'color': '#' + item.itemQuality.colorCode }"
      >
        {{ item.name }}
      </p>
      <p
        v-if="item.itemQualityId && !item.itemQuality"
        :style="{'color': '#' + itemQualities[item.itemQualityId].colorCode}"
      >
        {{ item.name }}
      </p>
      <p v-if="!item.itemQualityId && !item.itemQuality && !item.items">
        {{ item.name }}
      </p>
    </div>
    <p
      v-if="item.itemLevel"
      class="text-tooltip-misc"
    >
      Item Level {{ item.itemLevel }}
    </p>
    <p
      v-if="item.area"
    >
      {{ item.area.name }}
    </p>
    <p v-if="item.itemBondingTypeId">
      {{ bindingTypes[item.itemBondingTypeId - 1].name }}
    </p>
    <div v-if="item.enchantment && item.enchantment.name">
      <p>{{ item.enchantment.name }}</p>
      <p
        v-for="condition in item.enchantment.gemConditions"
        :key="condition.id"
        class="text-item-poor"
      >
        {{ condition.name }}
      </p>
    </div>
    <p v-if="item.maxCount">
      Unique {{ item.maxCount > 1 ? (item.maxCount) : '' }}
    </p>
    <p
      v-if="item.itemSubTypeId === 111"
      class="q-uncommon"
    >
      &lt;Right Click to Open&gt;
    </p>
    <p v-if="item.startsQuest">
      This Item Begins a Quest
    </p>
    <p v-if="item.duration">
      Duration: {{ convertDuration(item.duration) }}
    </p>
    <p v-if="item.requiredSkill">
      Requires {{ item.requiredSkill.name }} ({{ item.requiredSkillRank }})
    </p>
    <p v-if="item.requiredSpell">
      Requires {{ item.requiredSpell.name }}
    </p>
    <p v-if="item.requiredRepFaction">
      Requires {{ item.requiredRepFaction.name }} ({{ item.requiredRepRank.name }})
    </p>
    <p v-if="item.containerSlots && item.itemSubType">
      {{ item.containerSlots }} Slot {{ item.itemSubType.name }}
    </p>
    <section class="flex justify-between w-full">
      <p
        v-if="item.itemSlot && item.itemSlot.name !== 'Bag' "
        class="pr-10"
      >
        {{ item.itemSlot.name }}
      </p>
      <p
        v-if="item.itemSubType && item.itemSubType.name"
        class="float-right text-right"
      >
        {{ [2, 4, 6].includes(item.itemTypeId) ? (item.itemSubType.name !== 'Miscellaneous' ? item.itemSubType.name : null ) : null }}
      </p>
    </section>

    <section
      v-if="item.dmg1Min"
      class="flex justify-between w-full"
    >
      <p
        class="pr-10"
      >
        {{ item.dmg1Min }} - {{ item.dmg1Max }} <span v-if="item.dmg1Type && item.dmg1Type.id && item.dmg1Type.id !== 1">
          {{ item.dmg1Type.id ? item.dmg1Type.name : '' }}

        </span> Damage
      </p>
      <p class="float-right text-right">
        Speed {{ (item.weaponSpeed / 1000).toFixed(2) }}
      </p>
    </section>
    <p v-if="item.dmg2Min">
      + {{ item.dmg2Min }} - {{ item.dmg2Max }} {{ item.dmg2Type && item.dmg2Type.id !== 1 ? item.dmg2Type.name : '' }} Damage
    </p>
    <p v-if="item.dmg1Min">
      ({{ (((item.dmg1Min + item.dmg1Max) / 2 + (item.dmg2Min + item.dmg2Max) / 2) / (item.weaponSpeed / 1000)).toFixed(1) }} damage per second)
    </p>
    <p v-if="item.armor">
      {{ item.armor }} Armor
    </p>
    <p v-if="item.block">
      {{ item.block }} Block
    </p>
    <div v-if="item.stats && item.stats.length">
      <p
        v-for="stat in item.stats.filter(stat => ![12,13,14,15,18,30,21,20,31,32,35,36,37,38,44,45].includes(stat.id))"
        :key="stat.name"
      >
        {{ stat.ItemStats.value > 0 ? '+' : '' }}{{ stat.ItemStats.value }} {{ stat.name }}
      </p>
    </div>
    <p
      v-if="item.randomProperties && item.randomProperties.length"
      class="text-item-uncommon"
    >
      &lt;Random Enchantment&gt;
    </p>
    <p v-if="item.resistanceArcane">
      +{{ item.resistanceArcane }} Arcane Resistance
    </p>
    <p v-if="item.resistanceFire">
      +{{ item.resistanceFire }} Fire Resistance
    </p>
    <p v-if="item.resistanceFrost">
      +{{ item.resistanceFrost }} Frost Resistance
    </p>
    <p v-if="item.resistanceNature">
      +{{ item.resistanceNature }} Nature Resistance
    </p>
    <p v-if="item.resistanceShadow">
      +{{ item.resistanceShadow }} Shadow Resistance
    </p>
    <div
      v-if="item.socketColor1 || item.socketColor2 || item.socketColor3 "
    >
      <section
        v-if="item.socketColor1"
        class="space-x-1"
      >
        <img
          class="w-5 inline"
          :src="`https://cdn.wowclassicdb.com/misc/socket_${item.socketColor1.toLowerCase()}.gif`"
        >
        <span class="text-item-poor">
          {{ item.socketColor1 }}
          Socket</span>
      </section>
      <section
        v-if="item.socketColor2"
        class="space-x-1"
      >
        <img
          class="w-5 inline"
          :src="`https://cdn.wowclassicdb.com/misc/socket_${item.socketColor2.toLowerCase()}.gif`"
        >
        <span class="text-item-poor">
          {{ item.socketColor2 }}
          Socket</span>
      </section>
      <section
        v-if="item.socketColor3"
        class="space-x-1"
      >
        <img
          class="w-5 inline"
          :src="`https://cdn.wowclassicdb.com/misc/socket_${item.socketColor3.toLowerCase()}.gif`"
        >
        <span class="text-item-poor">
          {{ item.socketColor3 }}
          Socket</span>
      </section>
      <span
        v-if="item.socketBonus && item.socketBonus.name"
        class="text-item-poor"
      > Socket Bonus: {{ item.socketBonus.name }}</span>
    </div>

    <p v-if="item.durability">
      Durability {{ item.durability }} / {{ item.durability }}
    </p>

    <section v-if="item.allowedClasses && item.allowedClasses.length && !item.items">
      <span>Classes: </span>
      <div
        v-for="(itemClass, index) in item.allowedClasses"
        :key="itemClass.id"
        class="inline pr-1"
      >
        <span
          :style="{'color': '#' + itemClass.colorCode }"
          class=""
        >{{ itemClass.name }}</span>
        <span v-if="index + 1 < item.allowedClasses.length">,</span>
      </div>
    </section>

    <section v-if="item.displayRaceOnTooltip">
      <span>Races: </span>
      <div
        v-for="(itemClass, index) in item.allowedRaces"
        :key="itemClass.id"
        class="inline pr-1"
      >
        <span
          :style="{'color': '#' + itemClass.colorCode }"
          class=""
        >{{ itemClass.name }}</span>
        <span v-if="index + 1 < item.allowedRaces.length">,</span>
      </div>
    </section>
    <p v-if="item.requiredLevel > 0 && !item.items">
      Requires Level {{ item.requiredLevel }}
    </p>
    <div v-if="item.stats">
      <p
        v-if="item.stats.find(stat => stat.id === 12)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Increases defense rating by {{ item.stats.find(stat => stat.id === 12).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 13)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Increases your dodge rating by {{ item.stats.find(stat => stat.id === 13).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 35)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves your resilience rating by {{ item.stats.find(stat => stat.id === 35).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 14)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves your parry rating by {{ item.stats.find(stat => stat.id === 14).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 20)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves ranged critical strike rating by {{ item.stats.find(stat => stat.id === 20).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 15)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Increases your shield block rating by {{ item.stats.find(stat => stat.id === 15).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 18)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves spell hit rating by {{ item.stats.find(stat => stat.id === 18).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 30)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves spell haste rating by {{ item.stats.find(stat => stat.id === 30).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 21)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves spell critical strike rating by {{ item.stats.find(stat => stat.id === 21).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 31)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves hit rating by {{ item.stats.find(stat => stat.id === 31).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 32)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves critical strike rating by {{ item.stats.find(stat => stat.id === 32).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 38)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Increases attack power by {{ item.stats.find(stat => stat.id === 38).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 37)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Increases your expertise rating by {{ item.stats.find(stat => stat.id === 37).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 36)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves haste rating by {{ item.stats.find(stat => stat.id === 36).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 44)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves armor penetration rating by {{ item.stats.find(stat => stat.id === 44).ItemStats.value }}.
      </p>
      <p
        v-if="item.stats.find(stat => stat.id === 45)"
        class="text-item-uncommon max-w-sm"
      >
        Equip: Improves spell power by {{ item.stats.find(stat => stat.id === 45).ItemStats.value }}.
      </p>
    </div>
    <section v-if="item.spellEffects">
      <p
        v-for="(spell, i) in item.spellEffects"
        :key="spell.id"
        class="max-w-sm text-item-uncommon"
      >
        <router-link
          v-if="spellTrigger(i, item) && spell.name !== 'Learning' && parseSpellEffectDescription(spell)?.length"
          v-tooltip="{data: spell, type: 'spell'}"
          :to="`/spell/${spell.id}`"
          class="block text-item-uncommon hover:text-item-uncommon hover:underline"
        >
          {{ spellTrigger(i, item).name === 'On Equip' ? 'Equip' : spellTrigger(i, item).name }}:
          {{ taughtSpellName === 'Learning' ? item.description : parseSpellEffectDescription(spell) }}
          {{ item.spellCooldown && item.spellCooldown > 0 ? `(${convertTime(item.spellCooldown)} cooldown) ` : null }}
        </router-link>
        <router-link
          v-if="taughtSpellName === 'Learning' && parseSpellEffectDescription(spell)?.length"
          v-tooltip="{data: spell, type: 'spell'}"
          :to="`/spell/${spell.id}`"
          class="block text-item-uncommon hover:text-item-uncommon hover:underline"
        >
          {{ parseSpellEffectDescription(spell) }}
        </router-link>
      </p>
    </section>
    <p v-if="item.spellCharges">
      {{ item.spellCharges }} Charges
    </p>
    <section v-if="item.itemSets && item.itemSets.length">
      <p
        v-if="item.itemSets[0].name"
        class="mt-5 text-tooltip-misc"
      >
        {{ item.itemSets[0].name }} (0/{{ item.itemSets[0].items.length }})
      </p>
      <p v-if="item.itemSets[0].skill">
        Requires {{ item.itemSets[0].skill.name }} ({{ item.itemSets[0].requiredSkill }})
      </p>
      <div class="mb-5">
        <p
          v-for="setItem in item.itemSets[0].items"
          :key="setItem.id"
          class="pl-2 text-item-poor"
        >
          &nbsp;&nbsp;{{ setItem.name }}
        </p>
      </div>
      <p
        v-for="spell in item.itemSets[0].spells"
        :key="spell.id"
        class="text-item-poor max-w-sm"
      >
        ({{ spell.ItemSetBoni.amountOfPieces }}) Set: {{ spell.description }}
      </p>
    </section>

    <p
      v-if="item.description && !item.objectives"
      v-show="taughtSpellName !== 'Learning'"
      class="block leading-relaxed text-tooltip-misc max-w-sm line-clamp-4 break-words"
      v-html="item.description"
    />

    <p
      v-if="item.objectives"
      class="block leading-relaxed break-words text-tooltip-misc w-72 line-clamp-4"
      v-html="item.objectives"
    />
    <ul
      v-if="item.requiredItems"
      class="p-2 space-y-2 max-w-max"
    >
      <h2 v-if="item.requiredItems.length">
        Requirements:
      </h2>
      <li
        v-for="(item, i) in item.requiredItems"
        :key="`req-item-${i}`"
        class="flex items-center space-x-2"
      >
        <img
          class="w-7"
          :src="`https://cdn.wowclassicdb.com/icons/${item.icon?.name}.jpg`"
          :alt="item.name"
        >
        <span>{{ item.QuestRequiredItems.amount > 1 ? `${item.QuestRequiredItems.amount}x ` : '' }}</span>
        <span
          class="hover:text-primary"
          :style="{'color': '#' + itemQualities[item.itemQualityId].colorCode }"
        > {{ item.name }}</span>
      </li>
    </ul>
    <p
      v-if="item.pageText"
      class="text-item-uncommon"
    >
      &lt;Click to Read&gt;
    </p>
    <p v-if="item.stackSize && item.stackSize !== 1">
      Stack Size: {{ item.stackSize }}
    </p>
    <section v-if="item.sellPrice">
      <span class="inline">Sell Price: </span>
      <Money :price="item.sellPrice" />
    </section>
  </div>
</template>
<script>
import Money from '../../../../lib/src/components/Money.vue'
import { itemQualities } from '../../../../lib/src/constants/item-constants'
import tooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
import convertDuration from '../../../../lib/src/util/convert-duration'
import convertTime from '../../../../lib/src/util/convert-time'
import { parseDollarVariables, parseSpellEffectDescription } from '../../../../lib/src/util/stringUtils'
export default
{
  name: 'ItemTooltip',
  components: { Money },
  mixins: [
    tooltipMixin
  ],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  data: () => {
    return {
      itemQualities: itemQualities,
      itemSetBonuses: [],
      bindingTypes: [
        {
          id: 1,
          name: 'Binds when picked up'
        },
        {
          id: 2,
          name: 'Binds when equipped'
        },
        {
          id: 3,
          name: 'Binds when used'
        },
        {
          id: 4,
          name: 'Quest Item'
        }
      ]
    }
  },
  computed: {
    taughtSpellName () {
      if (this.item.spellEffects) { return this.item.spellEffects[0]?.name } else return null
    }
  },
  created () {
    if (this.item.spells) {
      this.sortItemSetBonuses()
    }
    if (this.item.objectives && this.item.objectives.length > 0) {
      this.item.objectives = parseDollarVariables(this.item.objectives)
    }

    if (this.item.description && this.item.description.length > 0) {
      this.item.description = parseDollarVariables(this.item.description)
    }
  },
  methods: {
    parseSpellEffectDescription,
    spellTrigger (index, item) {
      if (item.spellTriggers) { return index >= item.spellTriggers?.length ? item.spellTriggers[item.spellTriggers.length - 1] : item.spellTriggers[index] } else return null
    },
    convertTime,
    convertDuration,
    sortItemSetBonuses () {
      this.itemSetBonuses = this.item.spells.sort((a, b) => {
        return a.ItemSetBoni.amountOfPieces - b.ItemSetBoni.amountOfPieces
      })
    }
  }
}
</script>
<style lang="postcss" scoped>
#tooltip {
  top: 5000px;
  left: 5000px;
}
</style>

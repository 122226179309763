<template>
  <div
    id="tooltip"
    class="p-2 text-left min-w-max"
  >
    <img
      v-if="skill.icon"
      class="absolute -top-0.5 inline w-12 h-auto border-2 border-tooltip-border -left-16"
      :src="`https://cdn.wowclassicdb.com/icons/${skill.icon.name}.jpg`"
      :alt="skill.name"
    >
    <div class="space-y-2">
      <h3>{{ skill.name }}</h3>

      <p
        v-if="skill.description"
        class="block leading-relaxed text-tooltip-misc w-72 line-clamp-4 break-words"
      >
        {{ skill.description }}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'SkillTooltip',
  props: {
    skill: {
      type: Object,
      required: true
    }
  }
})
</script>
<style lang="postcss" scoped>

  #tooltip {
    top: 5000px;
    left: 5000px;
  }
</style>

<template>
  <TemplateListPage
    :page-title="pageTitle"
    :columns="columns"
    :api-path="apiPath"
    single-page-prefix="currency"
  />
</template>

<script>
import TemplateListPage from '@/components/TemplateListPage'

export default {
  name: 'Currencies',
  components: {
    TemplateListPage
  },
  data () {
    return {
      columns: [
        { field: 'name', label: 'Name', sortable: true, icon: true, primary: true },
        { field: 'cap', label: 'Cap', sortable: true },
        { field: 'currencyCategory.name', label: 'Category', sortable: true }
      ],
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.pageTitle },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:description', vmid: 'og:description', content: 'View all Currencies from Wrath of the Lich King Classic.' },
        { property: 'description', vmid: 'description', content: 'View all Currencies from Wrath of the Lich King Classic.' }
      ]
    }
  },
  computed: {
    pageTitle () {
      return this.$store.getters.titleForRoute || 'Currencies'
    },
    apiPath () {
      return this.$route.path
    }
  }
}
</script>

<template>
  <TemplateSinglePage :show-loading="!title">
    <div
      v-if="title"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <div
        class="flex flex-wrap items-center justify-center p-0 m-0 font-sans text-base text-black md:justify-start "
      >
        <h1 class="mb-5 font-sans text-3xl font-bold">
          <span
            class="text-primary"
            v-html="formattedName"
          />
        </h1>
      </div>

      <quick-facts
        class="text-white mb-4"
        :data="quickFactsData"
      />

      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Title',
  components: {
    TemplateSinglePage,
    RelatedTableFilterButtonBase,
    Breadcrumbs,
    QuickFacts
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.title?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.title?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.title?.rewardFromAchievement, 'rewardFromAchievement', '', RelatedTableFilterOption.rewardFromAchievement),
        setRelatedTable(this.title?.rewardFromQuest, 'rewardFromQuest', '', RelatedTableFilterOption.rewardFromQuest),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {
        side: this.title.side ? this.title.side : null,
        expansion: this.title.expansion ? this.title.expansion : null
      }
    },
    metaDescription () {
      return `View Title ${this.title?.name} from Wrath of the Lich King Classic.`
    },
    title () {
      return this.$store.state.titles[this.$route.params.id]
    },
    tooltip () {
      return this.$store.state.tooltips.title[this.$route.params.id]
    },
    formattedName () {
      return this.title.name.replace('%s', '<span class="text-gray-300">&lt;Name&gt;</span>')
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchTitle()
  },
  updated () {
    if (!this.title) this.fetchTitle()
  },
  mounted () { // Client side fetching
    this.fetchTitle()
  },
  methods: {
    fetchTitle () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchTitle', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(() => {
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>

<template>
  <div
    v-if="achievementCriteriaExists"
    class="font-semibold"
  >
    <div
      v-if="reqCriteriaCount && reqCriteriaCount > 0"
      class="items-center block text-2xl bg-no-repeat h-7"
    >
      Criteria
      <span class="text-sm"
      > - Requires {{ reqCriteriaCount }} of {{ achievementCriteria.length }}
      </span>
    </div>
    <div class="flex flex-row ml-4 mt-2">
      <div
        v-for="(group, g) of 2"
        :key="g"
        class="flex flex-col"
      >
        <ul
          class="mt-2 mr-8"
          :class="{ 'list-square': hideCriteriaImages}"
        >
          <li
            v-for="(criteria, i) in g === 0 ? criteriaFirstGroup : criteriaSecondGroup"
            :key="i"
            class="mt-2"
          >
            <router-link
              v-if="getCriteriaType(criteria)"
              v-tooltip="{data: criteria[getCriteriaType(criteria)], type: getCriteriaType(criteria)}"
              class="text-item-poor flex"
              :to="`/${getTypeUrl(criteria.associatedColumnName)}/${criteria[criteria.associatedColumnName]}`"
            >
                <img
                  v-if="criteria[getCriteriaType(criteria)]?.icon"
                  :src="`https://cdn.wowclassicdb.com/icons/${criteria[getCriteriaType(criteria)].icon.name}.jpg`"
                  :alt="criteria[getCriteriaType(criteria)].name"
                  class="object-cover w-8 h-8 p-0 m-0 mr-2 text-black border border-solid      border-bg-900"
                >
                <span>{{ criteria.name }}</span>
            </router-link>
            <div
              v-else
              class="text-item-poor"
            >
              {{ criteria.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
export default {
  name: 'AchievementCriteria',
  mixins: [
    tooltipMixin
  ],
  props: {
    achievementCriteria: {
      type: Array,
      required: true
    },
    reqCriteriaCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    criteriaFirstGroup () {
      const breakPoint = this.achievementCriteria.length % 2 === 0 ? this.achievementCriteria.length / 2 : this.achievementCriteria.length / 2 + 1
      return this.achievementCriteria.slice(0, breakPoint)
    },
    criteriaSecondGroup () {
      const breakPoint = this.achievementCriteria.length % 2 === 0 ? this.achievementCriteria.length / 2 : this.achievementCriteria.length / 2 + 1
      return this.achievementCriteria.slice(breakPoint)
    },
    hideCriteriaImages () {
      let hideImages = false
      for (const criteria of this.achievementCriteria) {
        const criteriaData = criteria[this.getCriteriaType(criteria)]
        if (!criteriaData?.icon || criteria.associatedColumnName === null) {
          hideImages = true
          break;
        }
      }
      return hideImages
    },
    achievementCriteriaExists () {
      const criterias = this.achievementCriteria
      return criterias && criterias.length > 0 && criterias[0].name.length > 0
    }
  },
  methods: {
    getCriteriaType (criteria) {
      return criteria.associatedColumnName?.slice(0, -2)
    },
    getTypeUrl (associatedColumnName) {
      let pathName = ''
      if (associatedColumnName === 'areaId') {
        pathName = 'zone'
      } else if (associatedColumnName === 'locationId') {
        pathName = 'zone'
      } else if (associatedColumnName === 'spellId') {
        pathName = 'spell'
      } else if (associatedColumnName === 'questId') {
        pathName = 'quest'
      } else if (associatedColumnName === 'itemId') {
        pathName = 'item'
      } else if (associatedColumnName === 'factionId') {
        pathName = 'faction'
      } else if (associatedColumnName === 'gameObjectId') {
        pathName = 'object'
      } else if (associatedColumnName === 'achievementId') {
        pathName = 'achievement'
      }
      return pathName
    }
  }
}
</script>

<style scoped>
.mr-8 {
  margin-right: 2rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.list-square {
  list-style: square !important;
}

</style>

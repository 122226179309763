import { render, staticRenderFns } from "./AchievementTooltip.vue?vue&type=template&id=0904bb06&scoped=true&"
import script from "./AchievementTooltip.vue?vue&type=script&lang=ts&"
export * from "./AchievementTooltip.vue?vue&type=script&lang=ts&"
import style0 from "./AchievementTooltip.vue?vue&type=style&index=0&id=0904bb06&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0904bb06",
  null
  
)

export default component.exports
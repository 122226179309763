<template>
  <form
    class="w-full"
    @submit.prevent
  >
    <div
      class="flex flex-col flex-wrap w-full gap-5 mb-5 text-left md:flex-row"
    >
      <FieldWithLabel
        v-if="config.name"
        title="Name"
        label-for="name"
      >
        <input
          v-model="filters.name"
          type="text"
          name="name"
          class="default-input"
          @keydown="filter('name')"
        >
      </FieldWithLabel>
      <FieldWithLabel
        v-if="config.class"
        title="Class"
        label-for="classes"
      >
        <select
          id="classes"
          v-model="filters.class"
          name="classes"
          class="default-input"
          @change="filter('classId')"
        >
          <optgroup label="Default">
            <option :value="null">
              All Classes
            </option>
          </optgroup>
          <optgroup label="Choose One">
            <option
              v-for="playerClass in classes"
              :key="playerClass.name"
              :value="playerClass"
              :selected="
                filters.class && filters.class.id
                  ? playerClass.id === filters.class.id
                  : ''
              "
            >
              {{ playerClass.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>
      <FieldWithLabel
        v-if="config.npcType"
        title="NPC Type"
        label-for="npcType"
      >
        <select
          id="npcType"
          v-model="filters.npcType"
          name="npcType"
          class="default-input"
          @change="filter('npcTypeId')"
        >
          <optgroup label="Default">
            <option :value="null">
              All Types
            </option>
          </optgroup>
          <optgroup label="Choose One">
            <option
              v-for="type in npcTypes"
              :key="type.id"
              :value="type.id"
              :selected="
                filters.npcType && filters.npcType.id
                  ? type === filters.npcType.id
                  : ''
              "
            >
              {{ type.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>
      <FieldWithLabel
        v-if="config.itemSetClass"
        title="Class"
        label-for="itemSetClass"
      >
        <select
          id="itemSetClass"
          v-model="filters.itemSetClass"
          name="itemSetClass"
          class="default-input"
          @change="filter('allowedClasses')"
        >
          <optgroup label="Default">
            <option :value="null">
              All Classes
            </option>
          </optgroup>
          <optgroup label="Choose One">
            <option
              v-for="playerClass in classes"
              :key="playerClass.name"
              :value="playerClass"
              :selected="
                filters.itemSetClass && filters.itemSetClass.id
                  ? playerClass.id === filters.itemSetClass.id
                  : ''
              "
            >
              {{ playerClass.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>

      <FieldWithLabel
        v-if="config.slot"
        title="Slot"
        label-for="slot"
      >
        <select
          v-model="filters.itemSlot"
          name="slot"
          class="default-input"
          @change="filter('itemSlot')"
        >
          <optgroup label="Default">
            <option
              selected
              :value="null"
            >
              All Slots
            </option>
          </optgroup>
          <optgroup label="Item Slot">
            <option
              v-for="(slot, i) in itemSlots"
              :key="i"
              :value="slot.id"
            >
              {{ slot.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>

      <FieldWithLabel
        v-if="config.type"
        title="Type"
        label-for="type"
      >
        <select
          v-model="filters.itemType"
          name="type"
          class="default-input"
          @change="filter('itemType')"
        >
          <optgroup label="Default">
            <option :value="null">
              All Types
            </option>
          </optgroup>
          <optgroup label="Choose One">
            <option
              v-for="(type, i) in itemTypes"
              :key="i"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>
      <FieldWithLabel
        v-if="config.questSort"
        title="Type"
        label-for="questType"
      >
        <select
          v-model="filters.questSort"
          name="questType"
          class="default-input"
          @change="filter('questSort')"
        >
          <optgroup label="Default">
            <option :value="null">
              All Types
            </option>
          </optgroup>
          <optgroup label="Choose One">
            <option
              v-for="(type, i) in questSortTypes"
              :key="i"
              :value="type.ids"
            >
              {{ type.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>
      <FieldWithLabel
        v-if="config.itemSubType && subTypesForRoute.length > 0"
        class="relative"
        title="Sub Type"
        label-for="itemSubType"
      >
        <select
          v-model="filters.itemSubType"
          name="itemSubType"
          class="default-input"
          @change="filter"
        >
          <optgroup label="Choose One">
            <option
              selected
              :value="null"
            >
              All Subtypes
            </option>
            <option
              v-for="(type, index) in subTypesForRoute"
              :key="index"
              :value="type.itemSubType"
            >
              {{ type.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>

      <div
        v-if="config.quality"
        class="relative flex-grow"
      >
        <FieldWithLabel
          title="Quality"
          label-for="quality"
        >
          <fieldset class="flex justify-between">
            <select
              v-model="filters.minimumQuality"
              name="quality"
              class="default-input"
              @change="filter('minimumQuality')"
            >
              <optgroup label="Choose One">
                <option
                  v-for="(quality, i) in itemQualities"
                  :key="i"
                  :value="i - 1"
                >
                  {{ quality }}
                </option>
              </optgroup>
            </select>

            <span class="px-2 py-1 text-2xl text-gray-500">-</span>
            <select
              v-model="filters.maximumQuality"
              class="default-input"
              @change="filter('maximumQuality')"
            >
              <optgroup label="Choose One">
                <option
                  v-for="(quality, i) in itemQualities"
                  :key="i"
                  :value="i - 1"
                >
                  {{ quality }}
                </option>
              </optgroup>
            </select>
          </fieldset>
        </FieldWithLabel>
      </div>
      <div
        v-if="config.itemLevel"
        class="max-w-sm"
      >
        <FieldWithLabel
          title="Item Level"
          label-for="itemLevel"
        >
          <fieldset class="flex justify-between">
            <input
              v-model="filters.minimumItemLevel"
              name="itemLevel"
              type="number"
              number
              min="0"
              value="0"
              required
              max="200"
              class="default-input"
              @change="filter('minimumItemLevel')"
            >
            <span class="px-2 py-1 text-2xl text-gray-500">-</span>
            <input
              v-model="filters.maximumItemLevel"
              type="number"
              number
              min="1"
              required
              value="60"
              max="60"
              class="default-input"
              @change="filter('maximumItemLevel')"
            >
          </fieldset>
        </FieldWithLabel>
      </div>
      <div
        v-if="config.requiredLevel"
        class="max-w-sm"
      >
        <FieldWithLabel
          title="Required Level"
          label-for="level"
        >
          <fieldset class="flex justify-between">
            <input
              v-model="filters.minimumLevel"
              name="level"
              type="number"
              number
              min="0"
              value="0"
              required
              max="100"
              class="default-input"
              @change="filter('minimumLevel')"
            >
            <span class="px-2 py-1 text-2xl text-gray-500">-</span>
            <input
              v-model="filters.maximumLevel"
              type="number"
              number
              min="1"
              required
              value="60"
              max="60"
              class="default-input"
              @change="filter('maximumLevel')"
            >
          </fieldset>
        </FieldWithLabel>
      </div>
      <FieldWithLabel
        v-if="config.location"
        class="flex-grow"
        title="Location"
        label-for="location"
      >
        <select
          id="location"
          v-model="filters.location"
          name="location"
          class="default-input"
          @change="filter('location')"
        >
          <optgroup label="Default">
            <option
              selected
              :value="allLocationIds"
            >
              All Locations
            </option>
          </optgroup>
          <optgroup label="Eastern Kingdoms">
            <option :value="locationIdsInEasternKingdoms">
              All Eastern Kingdoms
            </option>
            <option
              v-for="location in locationsInEasternKingdoms"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </optgroup>
          <optgroup label="Kalimdor">
            <option :value="locationIdsInKalimdor">
              All Kalimdor
            </option>
            <option
              v-for="location in locationsInKalimdor"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </optgroup>
          <optgroup label="Outland">
            <option :value="locationIdsInOutland">
              All Outland
            </option>
            <option
              v-for="location in locationsInOutland"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </optgroup>
          <optgroup label="Raids">
            <option :value="locationIdsInRaids">
              All Raids
            </option>
            <option
              v-for="location in locationsInRaids"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </optgroup>
          <optgroup label="Battlegrounds">
            <option :value="locationIdsInBattlegrounds">
              All Battlegrounds
            </option>
            <option
              v-for="location in locationsInBattlegrounds"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </optgroup>
          <optgroup label="Dungeons">
            <option :value="locationIdsInDungeons">
              All Dungeons
            </option>
            <option
              v-for="location in locationsInDungeons"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </optgroup>
        </select>
      </FieldWithLabel>
      <FieldWithLabel
        v-if="config.itemSetType"
        title="Type"
        label-for="itemSetType"
        class="relative"
      >
        <div class="flex justify-between">
          <select
            v-model="filters.itemSetType"
            name="itemSetType"
            class="default-input"
            @change="filter"
          >
            <optgroup label="Choose One">
              <option
                selected
                :value="null"
              >
                All Types
              </option>
              <option
                v-for="type in itemSetTypes"
                :key="type.id"
                :value="type.id"
              >
                {{ type.name }}
              </option>
            </optgroup>
          </select>
        </div>
      </FieldWithLabel>
    </div>
  </form>
</template>

<script>
import locationMixin from '../../../../lib/src/mixins/location-mixin'
import { debounce } from 'lodash'
import filterData from '../../../../lib/src/mixins/filter-mixin'
import FieldWithLabel from './FieldWithLabel.vue'

export default {
  name: 'TableFilter',
  components: {
    FieldWithLabel
  },
  mixins: [filterData, locationMixin],
  props: {
    config: {
      type: Object,
      default: () => ({
        name: true,
        class: true,
        location: true,
        slot: true,
        type: true,
        quality: true,
        requiredLevel: true,
        phase: true,
        itemSetType: true,
        itemSubType: true
      })
    }
  },
  data () {
    return {
      options: [],
      selected: [],
      show: false,
      itemQualities: {
        1: 'Poor',
        2: 'Common',
        3: 'Uncommon',
        4: 'Rare',
        5: 'Epic',
        6: 'Legendary',
        7: 'Artifact'
      },
      questSortTypes: [
        { name: 'Class', ids: [61, 81, 82, 141, 161, 162, 261, 262, 263] },
        { name: 'Profession', ids: [24, 101, 121, 181, 182, 201, 264, 304, 324] },
        { name: 'Holiday', ids: [22, 364, 366, 369, 370] }
      ],
      checkedSources: [
        {
          id: 0,
          name: 'Starting Item'
        },
        {
          id: 1,
          name: 'Vendor'
        },
        {
          id: 2,
          name: 'Quest'
        },
        {
          id: 3,
          name: 'World Drop'
        },
        {
          id: 4,
          name: 'Gathered'
        },
        {
          id: 5,
          name: 'Pickpocketed'
        },
        {
          id: 6,
          name: 'Profession'
        },
        {
          id: 7,
          name: 'Battleground'
        },
        {
          id: 8,
          name: 'Dungeon'
        },
        {
          id: 9,
          name: 'Raid'
        },
        {
          id: 10,
          name: 'Conjured'
        },
        {
          id: 11,
          name: 'Crafted'
        },
        {
          id: 12,
          name: 'Reputation'
        },
        {
          id: 13,
          name: 'PvP Reward'
        },
        {
          id: 14,
          name: 'Unavailable'
        },
        {
          id: 15,
          name: 'Eastern Kingdoms'
        },
        {
          id: 16,
          name: 'Kalimdor'
        },
        {
          id: 17,
          name: 'Darkmoon Faire'
        },
        {
          id: 18,
          name: 'Dragons of Nightmare'
        },
        {
          id: 19,
          name: 'Scourge Invasion'
        },
        {
          id: 20,
          name: 'Holiday'
        }
      ],
      classes: [
        { name: 'Druid', id: 11 },
        { name: 'Hunter', id: 3 },
        { name: 'Mage', id: 8 },
        { name: 'Paladin', id: 2 },
        { name: 'Priest', id: 5 },
        { name: 'Rogue', id: 4 },
        { name: 'Shaman', id: 7 },
        { name: 'Warlock', id: 9 },
        { name: 'Warrior', id: 1 }
      ],
      itemTypes: [
        { id: 0, name: 'Consumable' },
        { id: 1, name: 'Container' },
        { id: 2, name: 'Weapon' },
        { id: 3, name: 'Gem' },
        { id: 4, name: 'Armor' },
        { id: 5, name: 'Reagent' },
        { id: 6, name: 'Projectile' },
        { id: 7, name: 'Trade Goods' },
        { id: 9, name: 'Recipe' },
        { id: 11, name: 'Quiver' },
        { id: 12, name: 'Quest' },
        { id: 13, name: 'Key' },
        { id: 15, name: 'Miscellaneous' },
        { id: 16, name: 'Glyph' }
      ],
      itemSetTypes: [
        {
          id: 0,
          name: 'Cloth'
        },
        {
          id: 1,
          name: 'Leather'
        },
        {
          id: 2,
          name: 'Mail'
        },
        {
          id: 3,
          name: 'Plate'
        },
        {
          id: 4,
          name: 'Miscellaneous'
        },
        {
          id: 5,
          name: 'Rings'
        },
        {
          id: 6,
          name: 'Trinkets'
        },
        {
          id: 7,
          name: 'Weapons'
        }
      ],
      itemSlots: [
        { id: 1, name: 'Head' },
        { id: 2, name: 'Neck' },
        { id: 3, name: 'Shoulder' },
        { id: 4, name: 'Shirt' },
        { id: 5, name: 'Chest' },
        { id: 6, name: 'Waist' },
        { id: 7, name: 'Legs' },
        { id: 8, name: 'Feet' },
        { id: 9, name: 'Wrists' },
        { id: 10, name: 'Hands' },
        { id: 11, name: 'Finger' },
        { id: 12, name: 'Trinket' },
        { id: 13, name: 'Weapon' },
        { id: 14, name: 'Shield' },
        { id: 15, name: 'Ranged' },
        { id: 16, name: 'Back' },
        { id: 17, name: 'Two-Hand' },
        { id: 18, name: 'Bag' },
        { id: 19, name: 'Tabard' },
        { id: 21, name: 'Main Hand' },
        { id: 22, name: 'Off Hand' },
        { id: 23, name: 'Holdable (Tome)' },
        { id: 24, name: 'Ammo' },
        { id: 25, name: 'Thrown' },
        { id: 27, name: 'Quiver' },
        { id: 28, name: 'Relic' }
      ],
      npcTypes: [
        { id: 0, name: 'None' },
        { id: 1, name: 'Beast' },
        { id: 2, name: 'Dragonkin' },
        { id: 3, name: 'Demon' },
        { id: 4, name: 'Elemental' },
        { id: 5, name: 'Giant' },
        { id: 6, name: 'Undead' },
        { id: 7, name: 'Humanoid' },
        { id: 8, name: 'Critter' },
        { id: 9, name: 'Mechanical' },
        { id: 10, name: 'Not Specified' },
        { id: 11, name: 'Totem' }
      ]
    }
  },

  mounted () {
    this.$store.dispatch('handleFilterChange', this.filters)
  },

  methods: {
    filter (fieldName) {
      debounce(() => {
        this.changeFilters(fieldName)
      }, 500)()
    }
  }
}
</script>

<style lang="postcss">
.multiselect__tags {
  @apply bg-bg-900 border-none text-white rounded;
}

.multiselect__content-wrapper {
  @apply bg-bg-900 text-footer-text border-bg-600;
}

.multiselect__option--highlight {
  @apply bg-bg-500;
}

.multiselect__tag {
  @apply bg-primary text-black rounded;
}

.multiselect__tag-icon {
  @apply hover:bg-bg-900 rounded-none;
}

.multiselect__tag-icon::after {
  @apply text-white;
}

.multiselect__input {
  @apply bg-bg-900 focus:outline-none focus:ring-0;
}
</style>

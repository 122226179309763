<template>
  <TemplateSinglePage :show-loading="!event">
    <div
      v-if="event"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <div
        class="flex flex-wrap items-center justify-center p-0 m-0 font-sans text-base text-black md:justify-start "
      >
        <div class="object-cover w-16 h-16 p-0 mb-5 mr-5 text-base">
          <img
            v-if="event.icon"
            :src="`https://cdn.wowclassicdb.com/icons/${event.icon}.jpg`"
            :alt="event.name"
            class="object-cover w-16 h-16 p-0 m-0 text-black border border-solid border-bg-900"
          >
        </div>
        <h1 class="mb-5 font-sans text-3xl font-bold text-primary">
          {{ event.name }}
        </h1>
      </div>
      <span
        v-if="event.holiday && event.holiday.description"
        class="block leading-relaxed max-w-lg line-clamp-4 break-words"
      >
        {{ event.holiday.description }}
      </span>
      <quick-facts
        :data="quickFactsData"
      />
      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'
import { formatTimestampIntoDateWithTime } from '../../../../lib/src/util'
export default {
  name: 'Event',
  components: {
    TemplateSinglePage,
    RelatedTableFilterButtonBase,
    Breadcrumbs,
    QuickFacts
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.event?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.event?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.event?.quests, 'quests', '', RelatedTableFilterOption.quests),
        setRelatedTable(this.event?.npcs, 'npcs', '', RelatedTableFilterOption.npcs),
        setRelatedTable(this.event?.items, 'items', '', RelatedTableFilterOption.items),
        setRelatedTable(this.event?.objects, 'objects', '', RelatedTableFilterOption.objects),
        setRelatedTable(this.event?.achievements, 'achievements', '', RelatedTableFilterOption.achievements),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {
        startTime: formatTimestampIntoDateWithTime(this.event.startTime),
        endTime: formatTimestampIntoDateWithTime(this.event.endTime),
        interval: this.event.interval,
        duration: this.event.length,
        boss: this.event.holiday?.boss,
        currencyCategory: this.event.eventCategory?.name
      }
    },
    metaDescription () {
      return `View event ${this.event?.name} from Wrath of the Lich King Classic.`
    },
    event () {
      return this.$store.state.events[this.$route.params.id]
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchEvent().catch(error => {
      console.error(error)
    })
  },
  updated () {
    if (!this.event) this.fetchEvent()
  },
  mounted () { // Client side fetching
    this.fetchEvent()
  },
  methods: {
    fetchEvent () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchEvent', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>


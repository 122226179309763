
import Vue, { PropType } from 'vue';
import { Currency } from '../../../../lib/src/models/Currency';
export default Vue.extend({
  name: 'CurrencyTooltip',
  props: {
    currency: {
      type: Object as PropType<Currency>,
      required: true
    }
  }
})

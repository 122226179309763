<template>
  <div
    class="fixed inset-0 z-50 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-start justify-center min-h-screen px-12 pt-10 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity bg-opacity-75 bg-bg-300"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        @click="close"
      >&#8203;</span>

      <div
        v-click-outside="close"
        class="inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl bg-bg-900 sm:my-8 sm:align-middle lg:max-w-3xl sm:w-full sm:p-6"
      >
        <div class="absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            class="rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary"
            @click="close"
          >
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg
              class="w-10 h-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div

          class="sm:flex sm:items-start"
        >
          <div

            class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-bg-900 sm:mx-0 sm:h-10 sm:w-10"
          >
            <!-- Hero Icons: Adjustments -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              id="modal-title"
              class="text-lg font-medium leading-6 text-gray-100"
            >
              Filters
            </h3>
            <div class="mt-2">
              <TableFilter :config="config" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import TableFilter from './TableFilter.vue';
export default {
  components: { TableFilter },
  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    config: {
      type: Object,
      default: () => ({
        name: true,
        class: true,
        location: true,
        slot: true,
        type: true,
        itemLevel: true,
        quality: true,
        requiredLevel: true,
        phase: true,
        itemSetType: true,
        itemSubType: true
      })
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }

}
</script>

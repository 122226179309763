<template>
  <div class="relative z-10 flex flex-shrink-0 h-16">
    <OffCanvasNav expansion="wotlk" />
    <button
      type="button"
      class="pl-4 text-primary focus:outline-none lg:hidden"
      @click="toggleSideMenu"
    >
      <span class="sr-only">Open sidebar</span>
      <!-- Heroicon name: outline/menu-alt-2 -->
      <svg
        class="w-8 h-8"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
    </button>
    <div class="flex justify-between flex-1 h-full px-4">
      <div class="flex items-center justify-center flex-1 h-full my-auto">
        <SearchBar />
      </div>
    </div>
  </div>
</template>

<script>
import OffCanvasNav from '../../../../lib/src/components/OffCanvasNav';
import SearchBar from './SearchBar.vue';
export default {
  components: { SearchBar, OffCanvasNav },
  methods: {
    toggleSideMenu () {
      this.$store.commit('toggleMobileSideNav')
    }
  }
}
</script>

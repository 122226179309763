<template>
  <div class="map">
    <div v-if="locationId && isError === false">
      <img
        v-show="isLoaded === true && isError === false"
        v-if="locationId"
        :class="!showFullscreenButton ? 'w-full h-auto max-h-[80vh]' : ''"
        :src="`https://cdn.wowclassicdb.com/maps/${locationId}.jpg`"
        alt="Map"
        @load="isLoaded = true"
      >
      <button
        v-if="showFullscreenButton"
        class="absolute items-center justify-center hidden w-16 h-16 p-1 transition-all border md:flex focus:outline-none focus:ring-4 ring-primary bottom-5 right-5 text-primary border-primary bg-bg-400 hover:bg-bg-500 hover:text-bg-400"
        @click="open"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-12 h-12"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
          />
        </svg>
      </button>
      <template v-for="(marker, i) in markersFormatted">
        <router-link
          :key="`marker-${i}`"
          :to="`/npc/${marker.id}`"
          target="_blank"
        >
          <img
            v-if="isLoaded === true"
            :style="{
              top: `calc(${marker.y}% - ${markerSize / 2}px)`,
              left: `calc(${marker.x}% - ${markerSize / 2}px${
                marker.offset ? ` + ${markerSize / 2}px` : ''
              })`,
              height: `${markerSize}px`
            }"
            :src="marker.file"
            alt="Map Marker"
            class="map-marker"
            :class="{ highlight: marker.highlight }"
            @mouseover="onMouseOver(i)"
            @mouseleave="onMouseLeave"
          >
        </router-link>
      </template>
      <Tooltip
        v-if="hoveredMarker"
        class="h-"
        :x="`${hoveredMarker.x}%`"
        :y="`${100 - hoveredMarker.y}%`"
        align-y="bottom"
      >
        <div
          style="display: inline-grid; grid-template-columns: fit-content fit-content;"
        >
          <p style="grid-column: 1 / span 2; margin: 2px; display: flex;">
            <span class="item-name misc">{{ hoveredMarker.label }}</span>
          </p>
          <p style="grid-column: 1 / span 2; margin: 2px; display: flex;">
            <span>({{ Math.round(hoveredMarker.x * 100) / 100 }},
              {{ Math.round(hoveredMarker.y * 100) / 100 }})</span>
          </p>
          <p
            v-if="hoveredMarker.description"
            style="grid-column: 1 / span 2; margin: 2px; margin-top: -2px; display: flex;"
          >
            <span>{{ hoveredMarker.description }}</span>
          </p>
        </div>
      </Tooltip>
    </div>
    <div v-if="isError">
      <h2 class="text-3xl text-primary">
        No Map Found
      </h2>
    </div>
    <div v-if="isLoaded === false && isError === false">
      <hearth-loading />
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip'
import HearthLoading from '../../../../lib/src/components/HearthLoading.vue'
export default {
  name: 'WorldMap',
  components: {
    Tooltip,
    HearthLoading
  },
  props: {
    locationId: {
      type: Number,
      default: 0
    },
    showFullscreenButton: {
      type: Boolean,
      default: true,
      required: false
    },
    markerSize: {
      type: Number,
      default: 22
    },
    markers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      hoveredMarker: null,
      isLoaded: false,
      isError: false
    }
  },
  computed: {
    markersFormatted () {
      return this.markers.map(m => {
        return {
          ...m,
          file: require(`@/assets/img/icons/map/atlas_map_marker_${m.type ||
            'point'}.png`)
        }
      })
    }
  },
  methods: {
    onMouseOver (i) {
      this.hoveredMarker = this.markersFormatted[i]
    },
    onMouseLeave () {
      this.hoveredMarker = null
    },
    open () {
      this.$emit('openMapModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  position: relative;

  .map-marker {
    opacity: 1;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    -webkit-transition: all 0.05s;
    transition: all 0.05s;

    &:hover,
    &.highlight {
      $brightness: 150%;

      filter: brightness($brightness);
      -webkit-filter: brightness($brightness);
      -moz-filter: brightness($brightness);
      -o-filter: brightness($brightness);
      -ms-filter: brightness($brightness);
      -webkit-transition: all 0.05s;
      transition: all 0.05s;
    }

    &.highlight {
      z-index: 3;
    }
  }

  cursor: pointer;
  cursor: hand;
}

</style>

<template>
  <TemplateSinglePage :show-loading="!skill">
    <div
      v-if="skill"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <div
        class="flex flex-wrap items-center justify-center p-0 m-0 font-sans text-base text-black md:justify-start "
      >
        <div class="object-cover w-16 h-16 p-0 mb-5 mr-5 text-base">
          <img
            v-if="skill.icon"
            :src="`https://cdn.wowclassicdb.com/icons/${skill.icon.name}.jpg`"
            :alt="skill.name"
            class="object-cover w-16 h-16 p-0 m-0 text-black border border-solid border-bg-900"
          >
        </div>
        <h1 class="mb-5 font-sans text-3xl font-bold text-primary">
          {{ skill.name }}
        </h1>
      </div>
      <div>
        <span class="flex flex-wrap">
          {{ skill.description }}
        </span>
      </div>
      <quick-facts
        class="text-white mb-4"
        :data="quickFactsData"
      />

      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Skill',
  components: {
    TemplateSinglePage,
    RelatedTableFilterButtonBase,
    Breadcrumbs,
    QuickFacts
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.skill?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.skill?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.skill?.classes, 'classes', '', RelatedTableFilterOption.classes),
        setRelatedTable(this.skill?.recipes, 'recipes', '', RelatedTableFilterOption.skillRecipes),
        setRelatedTable(this.skill?.recipeItems, 'recipeItems', '', RelatedTableFilterOption.skillRecipeItems),
        setRelatedTable(this.skill?.craftedItems, 'craftedItems', '', RelatedTableFilterOption.skillCraftedItems),
        setRelatedTable(this.skill?.requiredBy, 'requiredBy', '', RelatedTableFilterOption.skillRequiredBy),
        setRelatedTable(this.skill?.spells, 'spells', '', RelatedTableFilterOption.skillSpells),
        setRelatedTable(this.skill?.quests, 'quests', '', RelatedTableFilterOption.skillQuests),
        setRelatedTable(this.skill?.trainers, 'trainers', '', RelatedTableFilterOption.skillTrainers),
        setRelatedTable(this.skill?.classes, 'classes', '', RelatedTableFilterOption.classes),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {}
    },
    metaDescription () {
      return `View Skill ${this.skill?.name} from Wrath of the Lich King Classic.`
    },
    skill () {
      return this.$store.state.skills[this.$route.params.id]
    },
    tooltip () {
      return this.$store.state.tooltips.skill[this.$route.params.id]
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchSkill()
  },
  updated () {
    if (!this.skill) this.fetchSkill()
  },
  mounted () { // Client side fetching
    this.fetchSkill()
  },
  methods: {
    fetchSkill () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchSkill', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(() => {
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>

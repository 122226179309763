
import Vue from 'vue';

export default Vue.extend({
  props: {
    header: {
      type: String,
      required: true
    }
  }
})

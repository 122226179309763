<template>
  <TemplateListPage
    :page-title="pageTitle"
    :columns="columns"
    :api-path="apiPath"
    single-page-prefix="item"
  />
</template>

<script>
import TemplateListPage from '@/components/TemplateListPage'
import getItemTypes from '@/../../../lib/api/types/item'
import getColumnsFromTypes from '@/../../../lib/api/types/get-columns-from-types'

export default {
  name: 'Items',
  components: {
    TemplateListPage
  },
  data () {
    return {
      defaultColumns: [
        { field: 'name', label: 'Name', sortable: true, icon: true, primary: true },
        { field: 'requiredLevel', label: 'Req. Level', sortable: true },
        { field: 'itemLevel', label: 'Item Level', sortable: true }
      ],
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.pageTitle },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:description', vmid: 'og:description', content: 'View all items from Wrath of the Lich King Classic including categories and filters for Armor, Weapons and Class.' },
        { property: 'description', vmid: 'description', content: 'View all items from Wrath of the Lich King Classic including categories and filters for Armor, Weapons and Class.' }
      ]
    }
  },
  computed: {
    pageTitle () {
      return this.$store.getters.titleForRoute || 'Items'
    },
    apiPath () {
      return this.$route.path
    },
    columns () {
      const types = getItemTypes()
      return getColumnsFromTypes(types, this.$route.params, this.defaultColumns)
    }
  }
}
</script>

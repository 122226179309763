<template>
  <div class="h-full">
    <div class="w-20">
      <transition name="fade">
        <ItemTooltip
          v-if="shouldShowTooltip && showItemTooltip"
          id="tooltip"
          :item="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>

      <transition name="fade">
        <FactionTooltip
          v-if="shouldShowTooltip && showFactionTooltip"
          id="tooltip"
          :faction="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>

      <transition name="fade">
        <ZoneTooltip
          v-if="shouldShowTooltip && showZoneTooltip"
          id="tooltip"
          :zone="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <ItemSetTooltip
          v-if="shouldShowTooltip && showItemSetTooltip"
          id="tooltip"
          :item="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <SpellTooltip
          v-if="shouldShowTooltip && showSpellTooltip"
          id="tooltip"
          :spell="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <NPCTooltip
          v-if="shouldShowTooltip && showNpcTooltip"
          id="tooltip"
          :npc="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <ObjectTooltip
          v-if="shouldShowTooltip && showObjectTooltip"
          id="tooltip"
          :game-object="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <AchievementTooltip
          v-if="shouldShowTooltip && showAchievementTooltip"
          id="tooltip"
          :achievement="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <TitleTooltip
          v-if="shouldShowTooltip && showTitleTooltip"
          id="tooltip"
          :title="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <SkillTooltip
          v-if="shouldShowTooltip && showSkillTooltip"
          id="tooltip"
          :skill="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <PetTooltip
          v-if="shouldShowTooltip && showPetTooltip"
          id="tooltip"
          :pet="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <CurrencyTooltip
          v-if="shouldShowTooltip && showCurrencyTooltip"
          id="tooltip"
          :currency="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <RaceTooltip
          v-if="shouldShowTooltip && showRaceTooltip"
          id="tooltip"
          :race="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <ClassTooltip
          v-if="shouldShowTooltip && showClassTooltip"
          id="tooltip"
          :class-data="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
      <transition name="fade">
        <EventTooltip
          v-if="shouldShowTooltip && showEventTooltip"
          id="tooltip"
          :event="tooltipItem"
          class="absolute left-0 z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border box-border bg-opacity-95 top-52 max-w-max"
        />
      </transition>
    </div>
    <slot />
  </div>
</template>

<script>
import TooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
import AchievementTooltip from './AchievementTooltip.vue'
import ClassTooltip from './ClassTooltip.vue'
import CurrencyTooltip from './CurrencyTooltip.vue'
import EventTooltip from './EventTooltip.vue'
import FactionTooltip from './FactionTooltip.vue'
import ItemSetTooltip from './ItemSetTooltip.vue'
import ItemTooltip from './ItemTooltip.vue'
import NPCTooltip from './NPCTooltip.vue'
import ObjectTooltip from './ObjectTooltip.vue'
import PetTooltip from './PetTooltip.vue'
import RaceTooltip from './RaceTooltip.vue'
import SkillTooltip from './SkillTooltip.vue'
import SpellTooltip from './SpellTooltip.vue'
import TitleTooltip from './TitleTooltip.vue'
import ZoneTooltip from './ZoneTooltip.vue'

export default {
  components: { ItemTooltip, FactionTooltip, ItemSetTooltip, EventTooltip, NPCTooltip, ObjectTooltip, ZoneTooltip, SpellTooltip, AchievementTooltip, TitleTooltip, SkillTooltip, CurrencyTooltip, RaceTooltip, ClassTooltip, PetTooltip },

  mixins: [TooltipMixin],
  computed: {
    showNpcTooltip () {
      return this.tooltipType === 'npc'
    },
    showItemTooltip () {
      if (this.tooltipType === 'item') {
        return true
      }
      if (this.tooltipType === 'quest') {
        return true
      }
      return false
    },
    showItemSetTooltip () {
      return this.tooltipType === 'item-set'
    },
    showObjectTooltip () {
      return this.tooltipType === 'object'
    },
    showZoneTooltip () {
      return this.tooltipType === 'zone'
    },
    showSpellTooltip () {
      return this.tooltipType === 'spell'
    },
    showFactionTooltip () {
      return this.tooltipType === 'faction'
    },
    showAchievementTooltip () {
      return this.tooltipType === 'achievement'
    },
    showTitleTooltip () {
      return this.tooltipType === 'title'
    },
    showSkillTooltip () {
      return this.tooltipType === 'skill'
    },
    showPetTooltip () {
      return this.tooltipType === 'pet'
    },
    showCurrencyTooltip () {
      return this.tooltipType === 'currency'
    },
    showRaceTooltip () {
      return this.tooltipType === 'race'
    },
    showClassTooltip () {
      return this.tooltipType === 'class'
    },
    showEventTooltip () {
      return this.tooltipType === 'event'
    }
  }

}
</script>

<style lang="postcss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#tooltip {
  top: 5000px;
  left: 5000px;
}
</style>


import QuickFacts, { QuickFactsProps } from '@/components/QuickFacts.vue'
import Vue, { PropType } from 'vue'
import { Item } from '../../../../lib/src/models/Item'
import ItemTooltip from './ItemTooltip.vue'
import SpellTooltip from './SpellTooltip.vue'
export default Vue.extend({
  components: {
    ItemTooltip,
    SpellTooltip,
    QuickFacts
  },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true
    },
    miscInfo: Array
  },
  computed: {
    icon (): string {
      return `https://cdn.wowclassicdb.com/icons/${this.item.icon?.name}.jpg`
    },
    taughtSpell () {
      return this.item.spellEffects[1]
    },
    quickFactsData (): QuickFactsProps {
      return {
        icon: this.item.icon?.name,
        slot: this.item.itemSlot?.name,
        factionId: this.item.factionId,
        buyPrice: this.item.buyPrice,
        sellPrice: this.item.sellPrice,
        phase: this.item.phase,
        source: this.item.itemSource?.name,
        requiredDisenchantingSkill: this.item.requiredDisenchantSkill
      } as QuickFactsProps
    }
  }
})

<template>
  <TemplateSinglePage>
    <div class="list-view">
      <div class="flex items-center justify-between mb-8">
        <h1 class="font-sans text-3xl font-bold text-primary">
          {{ pageTitle }}
        </h1>
        <button
          class="flex items-center justify-center w-10 h-10 text-lg rounded-full outline-none md:hidden bg-bg-900 text-primary focus:outline-none"
          @click="toggleItemFilterModal()"
        >
          <!-- Hero Icons: Adjustments -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
          </svg>
        </button>
      </div>

      <ListViewTable
        :columns="columns"
        :api-path="apiPath"
        :single-page-prefix="singlePagePrefix"
        :show-filters="showFilters"
        expansion="wotlk"
      />
    </div>
  </TemplateSinglePage>
</template>

<script>
import TemplateSinglePage from '@/components/TemplateSinglePage';
import ListViewTable from '../../../../lib/src/components/list-view/ListViewTable';

export default {
  name: 'ListView',
  components: {
    ListViewTable,
    TemplateSinglePage
  },
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    apiPath: {
      type: String,
      required: true
    },
    singlePagePrefix: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showFilters: false
    }
  },
  methods: {
    toggleItemFilterModal () {
      this.showFilters = !this.showFilters
    }
  }
}
</script>

<style scoped lang="scss">
.list-view {
  display: flex;
  flex-direction: column;
}

::v-deep .list-view-table {
  @import '~@/scss/_mixins';

  tr {
    @apply border-bg-300;
  }
}

::v-deep .list-view-pagination {
  a {
    &:not(:first-child) {
      margin-left: 10px;
    }

    &:not(.disabled) {
      @apply bg-bg-500;
    }

    &.disabled {
      pointer-events: none;
    }

    &.active {
      @apply bg-primary ring-2 ring-primary font-bold text-black;
    }

    &:hover {
      &:not(.active) {
        @apply bg-primary;
      }
    }
  }
}
</style>

<template>
  <TemplateListPage
    :page-title="pageTitle"
    :columns="columns"
    :api-path="apiPath"
    single-page-prefix="enchantment"
  />
</template>

<script>
import TemplateListPage from '@/components/TemplateListPage'

export default {
  name: 'Enchantments',
  components: {
    TemplateListPage
  },
  data () {
    return {
      columns: [
        { field: 'name', label: 'Name', sortable: true, primary: true },
        { field: 'enchantmentCategories', format: (categories) => { return this.getEnchantmentCategoriesString(categories) }, label: 'Category', sortable: true }
      ],
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.pageTitle },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:description', vmid: 'og:description', content: 'View all Enchantments from Wrath of the Lich King Classic.' },
        { property: 'description', vmid: 'description', content: 'View all Enchantments from Wrath of the Lich King Classic.' }
      ]
    }
  },
  computed: {
    pageTitle () {
      return this.$store.getters.titleForRoute || 'Enchantments'
    },
    apiPath () {
      return this.$route.path
    }
  },
  methods: {
    getEnchantmentCategoriesString (row) {
      let str = ''
      for (const category of row.enchantmentCategories) {
        str += category.name + ', '
      }
      if (str.endsWith(', ')) {
        str = str.slice(0, -2)
      }
      return str
    }
  }
}
</script>

<template>
  <TemplateSinglePage :show-loading="!object">
    <div
      v-if="object"
      class="w-full h-full"
    >
      <Breadcrumbs />
      <h1
        id="item-name"
        class="hidden text-2xl font-bold md:block text-primary"
      >
        {{ object.name.trim() }}
      </h1>

      <div
        v-if="object.gameObjectSpawns && object.gameObjectSpawns.length && object.gameObjectSpawns[0].location"
        class="py-3 mt-6 space-y-6 xl:pb-0 sm:mt-4"
      >
        <h2 class="font-sans text-xl font-bold text-white ">
          Location
        </h2>
        <div
          class="max-w-3xl "
        >
          <WorldMapContainer
            :markers="markers"
            :locations="locations"
          />
        </div>
      </div>

      <object-overview
        :object="object"
      />

      <RelatedTableFilterButtonBase :filter-options="filterOptions" />
    </div>
  </TemplateSinglePage>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs.vue'
import WorldMapContainer from '@/components/MapContainer.vue'
import ObjectOverview from '@/components/ObjectOverview.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage.vue'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Object',
  components: {
    TemplateSinglePage,
    ObjectOverview,
    WorldMapContainer,
    RelatedTableFilterButtonBase,
    Breadcrumbs
  },
  metaInfo () {
    return {
      title: this.object?.name,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.object?.name },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:description', vmid: 'og:description', content: this.metaDescription },
        { property: 'description', vmid: 'description', content: this.metaDescription }
      ]
    }
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    metaDescription () {
      return `View Game Object ${this.object?.name} from Wrath of the Lich King Classic.`
    },
    markers () {
      const markers = []
      this.object.gameObjectSpawns.forEach((spawn) => {
        markers.push({
          x: spawn.posX,
          y: spawn.posY,
          location: spawn.locationId,
          id: this.object.id,
          label: this.object.name
        })
      })
      return markers
    },
    locations () {
      const locations = []
      this.object.gameObjectSpawns.forEach((spawn) => {
        if (!locations.find(l => l?.id === spawn.locationId)) {
          locations.push(spawn.location)
        }
      })
      return locations
    },
    filterOptions () {
      return [
        setRelatedTable(this.object?.sameModelAs, 'sameModelAs', '', RelatedTableFilterOption.sameModelAsObject),
        setRelatedTable(this.object?.starts, 'starts', '', RelatedTableFilterOption.startsQuests),
        setRelatedTable(this.object?.ends, 'ends', '', RelatedTableFilterOption.endsQuests),
        setRelatedTable(this.object?.contains, 'contains', '', RelatedTableFilterOption.contains),
        setRelatedTable(this.object?.fishing, 'fishing', '', RelatedTableFilterOption.fishing),
        setRelatedTable(this.object?.requiredForQuests, 'requiredForQuests', '', RelatedTableFilterOption.objectiveOfSource),
        setRelatedTable(this.object?.criteriaOf, 'criteriaOf', '', RelatedTableFilterOption.criteriaOf),
        setRelatedTable(this.object?.events, 'events', '', RelatedTableFilterOption.events),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    object () {
      return this.$store.state.objects[this.$route.params?.id]
    }

  },
  serverPrefetch () {
    // Server side fetching
    return this.fetchObject()
  },
  updated () {
    if (!this.object) this.fetchObject()
  },
  mounted () {
    if (!this.object) this.fetchObject()
  },
  methods: {
    fetchObject () {
      const page = this.$store.state.pageInfo?.currentPage
      return Promise.all([
        this.$store.dispatch('fetchObject', { id: this.$route?.params?.id, page: page })
      ])
    }
  }

}
</script>

import axios from 'axios'

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  json: true
})
export default {
  async execute (method, url) {
    return client({ method, url })
      .then(req => {
        return req.data
      })
      .catch(e => {
        // console.error(`ERROR: ${e}`)
      })
  },
  async getItem (id) {
    const data = await this.execute('get', `/item/${id}`)
    return data
  },
  fetchRelatedTableData (type, id, name, page) {
    // Fetch a specific related table for any type of entity
    return this.execute('get', `${type}/${id}/${name}?p=${page || 1}`)
  },
  getSearchResultsData (type, page = 1, limit, query) {
    return this.execute('get', `/search/result/${type}?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  async getEvent (id) {
    const data = await this.execute('get', `/event/${id}`)
    return data
  },
  getEventNpcs (id, page) {
    return this.execute('get', `/event/${id}/npcs?p=${page || 1}`)
  },
  getEventObjects (id, page) {
    return this.execute('get', `/event/${id}/objects?p=${page || 1}`)
  },
  getEventQuests (id, page) {
    return this.execute('get', `/event/${id}/quests?p=${page || 1}`)
  },
  getEventAchievements (id, page) {
    return this.execute('get', `/event/${id}/achievements?p=${page || 1}`)
  },
  getEventItems (id, page) {
    return this.execute('get', `/event/${id}/items?p=${page || 1}`)
  },
  getItemDroppedBy (id, page) {
    return this.execute('get', `/item/${id}/dropped-by?p=${page || 1}`)
  },
  getItemCreatedBy (id, page) {
    return this.execute('get', `/item/${id}/created-by?p=${page || 1}`)
  },
  getItemReagentFor (id, page) {
    return this.execute('get', `/item/${id}/reagent-for?p=${page || 1}`)
  },
  getItemSameModelAs (id, page) {
    return this.execute('get', `/item/${id}/same-model-as?p=${page || 1}`)
  },
  getItemToolFor (id, page) {
    return this.execute('get', `/item/${id}/tool-for?p=${page || 1}`)
  },
  getItemSkinnedBy (id, page) {
    return this.execute('get', `/item/${id}/skinned-by?p=${page || 1}`)
  },
  getItemSalvagedBy (id, page) {
    return this.execute('get', `/item/${id}/salvaged-by?p=${page || 1}`)
  },
  getItemPickpocketedBy (id, page) {
    return this.execute('get', `/item/${id}/pickpocketed-by?p=${page || 1}`)
  },
  getItemContainedIn (id, page) {
    return this.execute('get', `/item/${id}/contained-in?p=${page || 1}`)
  },
  getItemFishedFrom (id, page) {
    return this.execute('get', `/item/${id}/fished-from?p=${page || 1}`)
  },
  getItemFishedInZone (id, page) {
    return this.execute('get', `/item/${id}/fished-in-zone?p=${page || 1}`)
  },
  getItemRaces (id, page) {
    return this.execute('get', `/item/${id}/races?p=${page || 1}`)
  },
  getItemClasses (id, page) {
    return this.execute('get', `/item/${id}/classes?p=${page || 1}`)
  },
  getItemLoot (id, page) {
    return this.execute('get', `/item/${id}/loot?p=${page || 1}`)
  },
  getItemLootedFrom (id, page) {
    return this.execute('get', `/item/${id}/looted-from?p=${page || 1}`)
  },
  getItemDisenchanting (id, page) {
    return this.execute('get', `/item/${id}/disenchanting?p=${page || 1}`)
  },
  getItemDisenchantedFrom (id, page) {
    return this.execute('get', `/item/${id}/disenchanted-from?p=${page || 1}`)
  },
  getItemMilling (id, page) {
    return this.execute('get', `/item/${id}/milling?p=${page || 1}`)
  },
  getItemMilledFrom (id, page) {
    return this.execute('get', `/item/${id}/milled-from?p=${page || 1}`)
  },
  getItemProspecting (id, page) {
    return this.execute('get', `/item/${id}/prospecting?p=${page || 1}`)
  },
  getItemProspectedFrom (id, page) {
    return this.execute('get', `/item/${id}/prospected-from?p=${page || 1}`)
  },
  getItemUnlocks (id, page) {
    return this.execute('get', `/item/${id}/unlocks?p=${page || 1}`)
  },
  getItemSoldBy (id, page) {
    return this.execute('get', `/item/${id}/sold-by?p=${page || 1}`)
  },
  getItemRewardFromQuests (id, page) {
    return this.execute('get', `/item/${id}/reward-from-quests?p=${page || 1}`)
  },
  getItemRewardFromAchievements (id, page) {
    return this.execute('get', `/item/${id}/reward-from-achievements?p=${page || 1}`)
  },
  getItemCurrencyFor (id, page) {
    return this.execute('get', `/item/${id}/currency-for?p=${page || 1}`)
  },
  getItemRequiredSourceForQuests (id, page) {
    return this.execute('get', `/item/${id}/required-source-for-quests?p=${page || 1}`)
  },
  getItemRequiredForQuests (id, page) {
    return this.execute('get', `/item/${id}/required-for-quests?p=${page || 1}`)
  },
  async getIcon (id) {
    const data = await this.execute('get', `/icon/${id}`)
    return data
  },
  async getRace (id, page) {
    const data = await this.execute('get', `/race/${id}`)
    return data
  },
  async getRaceQuests (id, page) {
    return this.execute('get', `/race/${id}/quests?p=${page || 1}`)
  },
  async getRaceRacials (id, page) {
    return this.execute('get', `/race/${id}/racials?p=${page || 1}`)
  },
  async getRaceLanguages (id, page) {
    return this.execute('get', `/race/${id}/languages?p=${page || 1}`)
  },
  async getRaceClasses (id, page) {
    return this.execute('get', `/race/${id}/classes?p=${page || 1}`)
  },
  async getClassSpells  (id, page) {
    return this.execute('get', `/class/${id}/spells?p=${page || 1}`)
  },
  async getClassItems  (id, page) {
    return this.execute('get', `/class/${id}/items?p=${page || 1}`)
  },
  async getClassQuests  (id, page) {
    return this.execute('get', `/class/${id}/quests?p=${page || 1}`)
  },
  async getClassItemSets  (id, page) {
    return this.execute('get', `/class/${id}/itemSets?p=${page || 1}`)
  },
  async getClassRaces  (id, page) {
    return this.execute('get', `/class/${id}/races?p=${page || 1}`)
  },
  async getClassData (id) {
    const data = await this.execute('get', `/class/${id}`)
    return data
  },
  getIconUsedByItems (id, page) {
    return this.execute('get', `/icon/${id}/used-by-items?p=${page || 1}`)
  },
  getIconUsedBySpells (id, page) {
    return this.execute('get', `/icon/${id}/used-by-spells?p=${page || 1}`)
  },
  getIconUsedByAchievements (id, page) {
    return this.execute('get', `/icon/${id}/used-by-achievements?p=${page || 1}`)
  },
  async getFaction (id) {
    const data = await this.execute('get', `/faction/${id}`)
    return data
  },
  async getPet (id) {
    const data = await this.execute('get', `/pet/${id}`)
    return data
  },
  async getEnchantment (id) {
    const data = await this.execute('get', `/enchantment/${id}`)
    return data
  },
  async getAchievement (id) {
    const data = await this.execute('get', `/achievement/${id}`)
    return data
  },
  async getTitle (id) {
    const data = await this.execute('get', `/title/${id}`)
    return data
  },
  async getCurrency (id) {
    const data = await this.execute('get', `/currency/${id}`)
    return data
  },
  getFactionNpcs (id, page) {
    return this.execute('get', `/faction/${id}/npcs?p=${page || 1}`)
  },
  getFactionQuests (id, page) {
    return this.execute('get', `/faction/${id}/quests?p=${page || 1}`)
  },
  getFactionItems (id, page) {
    return this.execute('get', `/faction/${id}/items?p=${page || 1}`)
  },
  getPetSpells (id, page) {
    return this.execute('get', `/pet/${id}/spells?p=${page || 1}`)
  },
  getPetTalents (id, page) {
    return this.execute('get', `/pet/${id}/talents?p=${page || 1}`)
  },
  getPetDiet (id, page) {
    return this.execute('get', `/pet/${id}/diet?p=${page || 1}`)
  },
  getPetTameables (id, page) {
    return this.execute('get', `/pet/${id}/tameables?p=${page || 1}`)
  },
  getEnchantmentUsedByGem (id, page) {
    return this.execute('get', `/enchantment/${id}/used-by-gem?p=${page || 1}`)
  },
  getEnchantmentUsedByUseItems (id, page) {
    return this.execute('get', `/enchantment/${id}/used-by-use-item?p=${page || 1}`)
  },
  getEnchantmentUsedBySpell (id, page) {
    return this.execute('get', `/enchantment/${id}/used-by-spell?p=${page || 1}`)
  },
  async getItemSet (id) {
    const data = await this.execute('get', `/item-set/${id}`)
    return data
  },
  async getQuest (id) {
    const data = await this.execute('get', `/quest/${id}`)
    return data
  },
  async getZone (id) {
    const data = await this.execute('get', `/zone/${id}`)
    return data
  },
  getZoneSubLocations (id, page) {
    return this.execute('get', `/zone/${id}/sub-locations?p=${page || 1}`)
  },
  getZoneFishing (id, page) {
    return this.execute('get', `/zone/${id}/fishing?p=${page || 1}`)
  },
  getZoneQuestRewards (id, page) {
    return this.execute('get', `/zone/${id}/quest-rewards?p=${page || 1}`)
  },
  getZoneNpcs (id, page) {
    return this.execute('get', `/zone/${id}/npcs?p=${page || 1}`)
  },
  getZoneQuests (id, page) {
    return this.execute('get', `/zone/${id}/quests?p=${page || 1}`)
  },
  getZoneObjects (id, page) {
    return this.execute('get', `/zone/${id}/objects?p=${page || 1}`)
  },
  async getSpell (id) {
    const data = await this.execute('get', `/spell/${id}`)
    return data
  },
  getCriteriaOf (type, id, page) {
    return this.execute('get', `/${type}/${id}/criteria-of?p=${page || 1}`)
  },
  getAchievementRewards (type, id, page) {
    return this.execute('get', `/${type}/${id}/rewards?p=${page || 1}`)
  },
  getTitleRewardFromAchievement (id, page) {
    return this.execute('get', `/title/${id}/reward-from-achievement?p=${page || 1}`)
  },
  getTitleRewardFromQuest (id, page) {
    return this.execute('get', `/title/${id}/reward-from-quest?p=${page || 1}`)
  },
  getCurrencyRewardFromQuest (id, page) {
    return this.execute('get', `/currency/${id}/reward-from?p=${page || 1}`)
  },
  getCurrencyDroppedByNPC (id, page) {
    return this.execute('get', `/currency/${id}/dropped-by?p=${page || 1}`)
  },
  getCurrencyContainedInItemLoot (id, page) {
    return this.execute('get', `/currency/${id}/contained-in-item-loot?p=${page || 1}`)
  },
  getCurrencyContainedInGameObject (id, page) {
    return this.execute('get', `/currency/${id}/contained-in-gameobject?p=${page || 1}`)
  },
  getCurrencySoldByNPC (id, page) {
    return this.execute('get', `/currency/${id}/sold-by?p=${page || 1}`)
  },
  getCurrencyForItems (id, page) {
    return this.execute('get', `/currency/${id}/currency-for?p=${page || 1}`)
  },
  getSpellRaces (id, page) {
    return this.execute('get', `/spell/${id}/races?p=${page || 1}`)
  },
  getSpellClasses (id, page) {
    return this.execute('get', `/spell/${id}/classes?p=${page || 1}`)
  },
  getSpellCastedBy (id, page) {
    return this.execute('get', `/spell/${id}/casted-by?p=${page || 1}`)
  },
  getSpellTaughtBy (id, page) {
    return this.execute('get', `/spell/${id}/taught-by?p=${page || 1}`)
  },
  getSpellUsedBy (id, page) {
    return this.execute('get', `/spell/${id}/used-by?p=${page || 1}`)
  },
  getSpellRanks (id, page) {
    return this.execute('get', `/spell/${id}/ranks?p=${page || 1}`)
  },
  getSpellUsedByItem (id, page) {
    return this.execute('get', `/spell/${id}/used-by-item?p=${page || 1}`)
  },
  getSpells (page = 1, first) {
    const url = ['spells', first].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  async getNPC (id) {
    const data = await this.execute('get', `/npc/${id}`)
    return data
  },
  async getObject (id) {
    const data = await this.execute('get', `/object/${id}`)
    return data
  },
  getObjectRequiredForQuests (id, page) {
    return this.execute('get', `/object/${id}/required-for-quests?p=${page || 1}`)
  },
  getObjectStarts (id, page) {
    return this.execute('get', `/object/${id}/starts-quests?p=${page || 1}`)
  },
  getObjectSameModelAs (id, page) {
    return this.execute('get', `/object/${id}/same-model-as?p=${page || 1}`)
  },
  getObjectEnds (id, page) {
    return this.execute('get', `/object/${id}/ends-quests?p=${page || 1}`)
  },
  getObjectContains (id, page) {
    return this.execute('get', `/object/${id}/contains?p=${page || 1}`)
  },
  getObjectFishing (id, page) {
    return this.execute('get', `/object/${id}/fishing?p=${page || 1}`)
  },
  getNPCStartsQuests (id, page) {
    return this.execute('get', `/npc/${id}/starts-quests?p=${page || 1}`)
  },
  getNPCObjectiveOf (id, page) {
    return this.execute('get', `/npc/${id}/objective-of?p=${page || 1}`)
  },
  getNPCTeaches (id, page) {
    return this.execute('get', `/npc/${id}/trains?p=${page || 1}`)
  },
  getNPCEndsQuests (id, page) {
    return this.execute('get', `/npc/${id}/ends-quests?p=${page || 1}`)
  },
  getNPCSells (id, page) {
    return this.execute('get', `/npc/${id}/sells?p=${page || 1}`)
  },
  getNPCDrops (id, page) {
    return this.execute('get', `/npc/${id}/drops?p=${page || 1}`)
  },
  getNPCDropsNormal10 (id, page) {
    return this.execute('get', `/npc/${id}/drops-normal10?p=${page || 1}`)
  },
  getNPCDropsNormal25 (id, page) {
    return this.execute('get', `/npc/${id}/drops-normal25?p=${page || 1}`)
  },
  getNPCDropsHeroic10 (id, page) {
    return this.execute('get', `/npc/${id}/drops-heroic10?p=${page || 1}`)
  },
  getNPCDropsHeroic25 (id, page) {
    return this.execute('get', `/npc/${id}/drops-heroic25?p=${page || 1}`)
  },
  getNPCSkinning (id, page) {
    return this.execute('get', `/npc/${id}/skinning?p=${page || 1}`)
  },
  getNPCSalvage (id, page) {
    return this.execute('get', `/npc/${id}/salvage?p=${page || 1}`)
  },
  getNPCPickpocketing (id, page) {
    return this.execute('get', `/npc/${id}/pickpocketing?p=${page || 1}`)
  },
  getNPCSpells (id, page) {
    return this.execute('get', `/npc/${id}/spells?p=${page || 1}`)
  },
  getNPCCriteriaOf (id, page) {
    return this.execute('get', `/npc/${id}/criteria-of?p=${page || 1}`)
  },
  getTypes (type = 'item') {
    return this.execute('get', `/${type}/types`)
  },
  getItems (page = 1, first, second, third) {
    const url = ['items', first, second, third].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getItemSets (page = 1, first) {
    const url = ['item-sets', first].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getQuests (page = 1, first, second) {
    const url = ['quests', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getAchievements (page = 1, first, second) {
    const url = ['achievements', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getCurrencies (page = 1, first, second) {
    const url = ['currencies', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getNPCs (page = 1, first, second) {
    const url = ['npcs', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getObjects (page = 1, first, second) {
    const url = ['objects', first, second].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getTooltip (id, type = 'item') {
    return this.execute('get', `/tooltips/${type}/${id}`)
  },
  getSearchSuggestions (query, limit) {
    return this.execute('get', `/search/suggestion?q=${query}&limit=${limit || 4}`)
  },
  getSearchResultsItems (page = 1, limit, query) {
    return this.execute('get', `/search/result/items?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsItemSets (page = 1, limit, query) {
    return this.execute('get', `/search/result/item-sets?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsQuests (page = 1, limit, query) {
    return this.execute('get', `/search/result/quests?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsNPCs (page = 1, limit, query) {
    return this.execute('get', `/search/result/npcs?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsSpells (page = 1, limit, query) {
    return this.execute('get', `/search/result/spells?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsCreatureSpells (page = 1, limit, query) {
    return this.execute('get', `/search/result/creature-spells?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsGameObjects (page = 1, limit, query) {
    return this.execute('get', `/search/result/game-objects?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsZones (page = 1, limit, query) {
    return this.execute('get', `/search/result/zones?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsFactions (page = 1, limit, query) {
    return this.execute('get', `/search/result/factions?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsEnchantments (page = 1, limit, query) {
    return this.execute('get', `/search/result/enchantments?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsPets (page = 1, limit, query) {
    return this.execute('get', `/search/result/pets?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getSearchResultsSkills (page = 1, limit, query) {
    return this.execute('get', `/search/result/skills?p=${page}&limit=${limit || 5}&q=${query}`)
  },
  getNews (limit = 3) {
    return this.execute('get', `/news?limit=${limit}`)
  },
  async getSkill (id) {
    const data = await this.execute('get', `/skill/${id}`)
    return data
  },
  getSkills (page = 1, first, second, third) {
    const url = ['skills', first, second, third].filter(s => s).join('/')
    return this.execute('get', `/${url}?p=${page}`)
  },
  getSkillRecipes (id, page) {
    return this.execute('get', `/skill/${id}/recipes?p=${page || 1}`)
  },
  getSkillRecipeItems (id, page) {
    return this.execute('get', `/skill/${id}/recipe-items?p=${page || 1}`)
  },
  getSkillCraftedItems (id, page) {
    return this.execute('get', `/skill/${id}/crafted-items?p=${page || 1}`)
  },
  getSkillRequiredBy (id, page) {
    return this.execute('get', `/skill/${id}/required-by?p=${page || 1}`)
  },
  async getSkillSpells (id, page) {
    const data = await this.execute('get', `/skill/${id}/spells?p=${page || 1}`)
    return data
  },
  getSkillQuests (id, page) {
    return this.execute('get', `/skill/${id}/quests?p=${page || 1}`)
  },
  getSkillTrainers (id, page) {
    return this.execute('get', `/skill/${id}/trainers?p=${page || 1}`)
  },
  getSkillClasses (id, page) {
    return this.execute('get', `/skill/${id}/classes?p=${page || 1}`)
  }

}

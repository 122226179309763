<template>
  <TemplateSinglePage :show-loading="!zone">
    <div
      v-if="zone"
      class="flex flex-col "
    >
      <Breadcrumbs />
      <SingleZone
        v-if="zone"
        :zone="zone"
      />
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import SingleZone from '@/components/SingleZone.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import { Zone } from '../../../../lib/src/models/Zone'

export default {
  components: {
    TemplateSinglePage,
    SingleZone,
    Breadcrumbs
  },
  metaInfo () {
    return {
      title: this.spell?.name,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.zone?.name },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:description', vmid: 'og:description', content: this.generateOGDescription },
        { property: 'description', vmid: 'description', content: this.generateOGDescription }
      ]
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    generateOGDescription () {
      let description = 'View Zone '
      if (this.zone?.area) { description += `${this.zone.area.name}` }
      if (this.zone?.suggestedLevel) { description += `, Level ${this.zone.suggestedLevel}` }
      if (this.zone?.suggestedLevel < 70) { description += '+' }
      if (this.zone?.area?.description) { description += `, ${this.zone.area.description}` }
      return description + ' from Wrath of the Lich King Classic.'
    },
    zone () {
      const selectedZone = this.$store.state.zones[this.$route.params.id]
      if (selectedZone) {
        return new Zone(this.$store.state.zones[this.$route.params.id])
      }
      return null
    }
  },
  serverPrefetch () { // Server side fetching
    return this.fetchZone()
  },
  mounted () { // Client side fetching
    if (!this.zone) this.fetchZone()
  },
  methods: {
    fetchZone () {
      const page = this.$store.state.pageInfo?.currentPage
      return Promise.all([
        this.$store.dispatch('fetchZone', { id: this.$route.params.id, page: page })
      ])
    }
  }
}
</script>

<template>
  <TemplateSinglePage :show-loading="!currency">
    <div
      v-if="currency"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <div
        class="flex flex-wrap items-center justify-center p-0 m-0 font-sans text-base text-black md:justify-start "
      >
        <div class="object-cover w-16 h-16 p-0 mb-5 mr-5 text-base">
          <img
            v-if="currency.icon"
            :src="`https://cdn.wowclassicdb.com/icons/${currency.icon.name}.jpg`"
            :alt="currency.name"
            class="object-cover w-16 h-16 p-0 m-0 text-black border border-solid border-bg-900"
          >
        </div>
        <h1 class="mb-5 font-sans text-3xl font-bold text-primary">
          {{ currency.name }}
        </h1>
      </div>

      <quick-facts
        class="text-white mb-4"
        :data="quickFactsData"
      />

      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Currency',
  components: {
    TemplateSinglePage,
    RelatedTableFilterButtonBase,
    Breadcrumbs,
    QuickFacts
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.currency?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.currency?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.currency?.rewardFromQuest, 'rewardFromQuest', '', RelatedTableFilterOption.rewardFromQuest),
        setRelatedTable(this.currency?.droppedByNPC, 'droppedByNPC', '', RelatedTableFilterOption.droppedBy),
        setRelatedTable(this.currency?.containedInItemLoot, 'containedInItemLoot', '', RelatedTableFilterOption.lootedFrom),
        setRelatedTable(this.currency?.containedInGameObject, 'containedInGameObject', '', RelatedTableFilterOption.containedIn),
        setRelatedTable(this.currency?.soldByNPC, 'soldByNPC', '', RelatedTableFilterOption.soldBy),
        setRelatedTable(this.currency?.currencyFor, 'currencyFor', '', RelatedTableFilterOption.currencyFor),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {
        icon: this.currency.icon?.name,
        cap: this.currency.cap > 0 ? this.currency.cap : null,
        currencyCategory: this.currency.currencyCategory.name
      }
    },
    metaDescription () {
      return `View Currency ${this.currency?.name} from Wrath of the Lich King Classic.`
    },
    currency () {
      return this.$store.state.currencies[this.$route.params.id]
    },
    tooltip () {
      return this.$store.state.tooltips.currency[this.$route.params.id]
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchCurrency()
  },
  updated () {
    if (!this.currency) this.fetchCurrency()
  },
  mounted () { // Client side fetching
    this.fetchCurrency()
  },
  methods: {
    fetchCurrency () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchCurrency', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(() => {
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>


import { Achievement } from '@/models/Achievement'
import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'AchievementTooltip',
  props: {
    achievement: {
      type: Object as PropType<Achievement>,
      required: true
    }
  },
  computed: {
    criteriaFirstGroup () {
      if (!this.achievement) return null;
      const breakPoint = this.achievement.achievementCriteria.length % 2 === 0 ? this.achievement.achievementCriteria.length / 2 : this.achievement.achievementCriteria.length / 2 + 1
      return this.achievement.achievementCriteria.slice(0, breakPoint)
    },
    criteriaSecondGroup () {
      if (!this.achievement) return null;
      const breakPoint = this.achievement.achievementCriteria.length % 2 === 0 ? this.achievement.achievementCriteria.length / 2 : this.achievement.achievementCriteria.length / 2 + 1
      return this.achievement.achievementCriteria.slice(breakPoint)
    },
    achievementCriteriaExists () {
      const criterias = this.achievement.achievementCriteria
      return criterias && criterias.length > 0 && criterias[0].name.length > 0
    }
  }
})
